.root {
    display: flex;
    flex-direction: 'row';
    justify-content: space-around;
    margin: 5%;
    height: '90vh'!important;
    padding: 50px, 0px!important;
}
.image {
    background: url("../../logoCLP.jpg") no-repeat center;
    background-repeat: 'no-repeat';
    background-position: 'center';
    background-size: 100% 100%;
    width: 45%;
    height: 600px;
}
.paper {
    display: 'flex';
    flex-wrap: wrap ;
    width: 40%;
    padding-left: 3%;
    padding-right: 3%;
    height: 720px;
    text-align: center;
    align-content:center;
}
.titulo {
    margin: 3px;
    font-size: xx-large;
    font-weight:700;
    margin-top: 100px;
}
.margenSuperior {
    margin-top: 40px;
}
@media screen and (min-width: 0px) and (max-width: 720px) {
    .image{ display: none; }
    .paper{ width: 80%; 
            margin-top: 20%; 
            height: 70%;}
    .titulo{ font-size: x-large;
             margin-top: 40px;}
}
@media screen and (min-height: 0px) and (max-height: 850px) {
    .image, .paper{ height: 400px; }
    .titulo {margin-top: 40px;}

}